.pgxpm-class {
    padding: 20px;
  }
  
  .pgxpm-class-profile {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
  }
  
  .age {
    margin: 20px 0;
    width: 100%;
  }
  
  .age h2 {
    font-size: 24px;
    text-align: center;
  }
  
  .age-sec ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .age-sec ul li {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 149px;
  }
  
  .age-sec {
    width: 300px;
    height: 297px;
    background: url('../assets/profile.png') no-repeat;
    background-size: contain;
    margin: auto;
  }
  
  .percent {
    font-size: 20px;
    font-weight: bold;
  }
  
  .number {
    font-size: 16px;
  }
  
  @media only screen and (max-width:767px) {

     .pgxpm-class-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  }